<template>
  <div class="masthead__menu" ref="hamburger">
    <div class="masthead__menu-burger" v-on:click="toggleNav">
      <burger-icon></burger-icon>
    </div>
    <div class="masthead__menu-wrapper" ref="thewrapper">
      <ul class="masthead__menu-list">
        <li
          v-for="item in menuItems"
          :key="item.key"
          :class="[
            'masthead__menu-list-' + item.key,
            { disabled: item.isDisabled },
          ]"
          @click="closeNav"
        >
          <router-link v-if="item.type == 'router'" :to="{ name: item.key }">{{
            item.text
          }}</router-link>
          <a v-else :href="item.url" target="_blank">{{ item.text }}</a>
        </li>
        <li class="masthead__menu-list-auth" @click="closeNav">
          <router-link :to="{ name: 'auth' }">{{
            this.authMessage()
          }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import burgerSVG from '../svg/icon-burger.svg.vue';
import { useUserStore } from '@/stores/userStore';

export default {
  name: 'site-menu',
  props: { menuItems: Array },
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
    };
  },
  data() {
    return {
      burgerOpen: false,
      burger: {
        wrapper: '.masthead__menu',
        menuWrapper: '.masthead__menu-wrapper',
        menuLength: 0,
        toggled: 'open',
      },
    };
  },
  components: {
    'burger-icon': burgerSVG,
  },
  mounted() {
    this.burger.menuLength = this.menuItems.length + 1; // must add 1 to account for auth button
  },
  methods: {
    toggleNav: function () {
      if (this.burgerOpen == false) {
        this.openNav();
      } else {
        this.closeNav();
      }
    },
    openNav() {
      console.log('🍔');
      this.$refs.hamburger.classList.add(this.burger.toggled);
      this.$refs.thewrapper.style.height = 40 * this.burger.menuLength + 'px';
      this.burgerOpen = true;
    },
    closeNav() {
      console.log('🚫🍔');
      this.$refs.hamburger.classList.remove(this.burger.toggled);
      this.$refs.thewrapper.style.height = 0;
      this.burgerOpen = false;
    },
    documentClick(tgt) {
      let el = this.$refs.hamburger;
      let target = tgt.target;

      if (this.burgerOpen == true && el !== target && !el.contains(target)) {
        this.closeNav();
      }
    },
    authMessage() {
      let authState = this.userStore.userLoggedIn;
      let authMsg = '';

      authState == false ? (authMsg = 'Log In') : (authMsg = 'Log Out');

      return authMsg;
    },
  },
  created() {
    document.addEventListener('click', this.documentClick);
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick);
  },
};
</script>

<style lang="less">
@import '/src/less/import-all.less';

//from menu.less
.masthead__menu {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 102;

  &-burger {
    width: @burger-width-sm;
    height: @burger-width-sm;
    background-color: @color-magenta;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color @default-transition;

    svg {
      width: 30px;
      height: 38px;
    }

    @{no-touch} &:hover {
      background-color: @color-black;

      svg {
        fill: @color-white;
      }
    }

    @media @md {
      order: 2;
    }
  }

  &-wrapper {
    height: 0;
    width: 0;
    background-color: @color-black;
    transition: width @springy-transition, height @springy-transition;
    overflow: hidden;

    ul {
      list-style: none;
      padding: 0;
    }

    li {
      text-align: center;

      &.disabled a {
        pointer-events: none;
        color: @color-light-grey;
        cursor: not-allowed;
      }
    }

    a {
      display: inline-block;
      font-weight: @font-normal-med;
      color: @color-white;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      font-size: 21px;
      text-decoration: none;
      text-align: center;
      padding: 2px 0 7px;
      margin-top: -2px;
      border-top: solid 2px transparent;

      @{no-touch} &:hover {
        color: @color-magenta;
        border-top-color: @color-white;
        text-decoration: none;
      }
    }

    @media @sm {
      width: 100%;
    }
  }

  @media @md {
    position: absolute;
    top: 28px;
    left: 0;
    bottom: auto;
    right: auto;
    flex-direction: row;
    align-items: flex-start;

    &-wrapper {
      width: 0;
      ul {
        margin: 16px 20px;
      }
    }

    &-burger {
      position: relative;
      left: (-@burger-width-lg / 2);
      width: @burger-width-lg;
      height: @burger-width-lg;
      transition: left @default-transition;

      @{no-touch} &:hover {
        left: 0;
      }
    }
  }

  &.open {
    .masthead__menu {
      &-burger {
        background-color: @color-black;

        @{no-touch} &:hover {
          background-color: @color-magenta;
        }

        @media @md {
          left: 0;
        }
      }

      &-wrapper {
        @media @md {
          width: @menu-width;
        }
      }
    }
  }
}

body.scrolled {
  .masthead {
    &__menu {
      @media @md {
        top: @menu-pos-md;
      }

      @media @lg {
        top: 0;
        left: unset;
        position: relative;

        &-burger {
          display: none;
        }

        &-list {
          margin-left: 0;
          margin-right: 0;
        }

        &-wrapper {
          background-color: transparent;
          width: auto;
          height: auto !important;

          li {
            display: inline-block;
            margin: 0 20px;

            &:last-child {
              margin-right: 0;
            }

            &.disabled a {
              color: @color-light-grey;
            }
          }

          a {
            color: @color-magenta;
            font-size: @menu-font-size-lg;

            @{no-touch} &:hover {
              color: @color-black;
              border-top-color: @color-magenta;
            }
          }
        }
      }
    }
  }
}

// home-specific styles
// body#home {
//   @media @xl {
//     .masthead__menu {
//       top: @menu-pos-xl;
//     }
//   }
// }
</style>
