<template>
  <header class="masthead">
    <div class="masthead__inner-wrapper">
      <div class="masthead__headline-wrapper">
        <div class="masthead__headline-block">
          <h1 class="masthead__headline">
            <router-link :to="{ name: 'home' }">{{
              this.siteData.myName
            }}</router-link>
          </h1>
          <aside
            class="masthead__aside"
            v-html="siteData.tagline"
            v-if="this.$route.name === 'home'"
          ></aside>
        </div>
        <masthead-links
          v-if="this.$route.name === 'home'"
          :linx="siteData.mastheadLinks"
        ></masthead-links>
      </div>
      <site-menu :menuItems="siteData.siteNav"></site-menu>
      <p
        class="masthead__intro"
        v-if="this.$route.name === 'home'"
        v-html="siteData.bio"
      ></p>
    </div>
  </header>
</template>

<script>
import mastheadLinks from './mastheadLinks.vue';
import siteMenu from './siteMenu.vue';

export default {
  name: 'site-masthead',
  props: ['siteData'],
  components: {
    'masthead-links': mastheadLinks,
    'site-menu': siteMenu,
  },
};
</script>

<style lang="less">
@import '/src/less/import-all.less';

// from masthead.less
.masthead {
  &__inner-wrapper {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    @media @lg {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @media @xl {
      align-items: flex-start;
    }
  }

  &__headline {
    display: inline-block;
    flex-grow: 1;
    margin: 0 0 2px;
    border-top: solid @tl-height @color-highlight;
    text-align: center;
    padding-top: 10px;
    position: relative;
    z-index: 101;

    a {
      text-decoration: none;
      color: @color-highlight;

      @{no-touch} &:hover {
        text-decoration: none;
      }
    }

    @media @md {
      margin-bottom: 10px;
    }

    @media @xl {
      flex-grow: 0;
    }

    &-wrapper {
      @media @lg {
        display: flex;
        width: 100%;
        align-items: center;
      }

      @media @xl {
        align-items: flex-start;
        width: 100%;
        flex-direction: column;
      }
    }

    &-block {
      @media @xl {
        width: 100%;
        display: flex;
        align-items: baseline;
      }
    }
  }

  &__aside {
    font-family: @font-mono;
    font-size: @aside-sm;
    line-height: 1;
    text-align: center;
    letter-spacing: -0.05px;
    text-transform: lowercase;

    @media @md {
      font-size: @aside-lg;
    }

    @media @lg {
      text-align: left;
    }

    @media @xl {
      text-align: right;
      flex-grow: 100;
    }
  }

  &__links {
    @links-height: 26px;

    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 20px 0 30px;
    padding: 0;
    justify-content: space-around;
    height: @links-height;
    width: 266px;

    @media @md {
      margin-left: auto;
      margin-right: auto;
    }

    @media @lg {
      margin-right: 0;
    }

    @media @xl {
      margin-left: 0;
      justify-content: space-between;
    }

    li {
      margin: 0;
      padding: 0;

      a {
        display: inline-block;
        width: @links-height;
        height: @links-height;

        svg {
          width: @links-height;
          height: @links-height;

          path {
            fill: @color-mid-grey;
          }
        }
      }
    }

    &--linkedin a {
      @{no-touch} &:hover {
        svg path {
          fill: @color-linkedin;
        }
      }
    }

    &--github a {
      @{no-touch} &:hover {
        svg path {
          fill: @color-black;
        }
      }
    }

    &--instagram a {
      @{no-touch} &:hover {
        svg path {
          fill: @color-insta;
        }
      }
    }

    &--strava a {
      @{no-touch} &:hover {
        svg path {
          fill: @color-strava;
        }
      }
    }
  }

  &__intro {
    .xs-container;
    font-family: @font-mono-light;
    font-size: @intro-sm;
    line-height: @intro-sm-lh;
    color: @color-dark-grey;

    strong {
      font-family: @font-mono-med;
    }

    @media @md {
      width: @bp-container-md;
      font-size: @intro-md;
      line-height: @intro-md-lh;
    }

    @media @lg {
      width: 100%;
      margin-top: 40px;
      font-size: @intro-lg;
      line-height: @intro-lg-lh;
    }

    @media @xl {
      width: 666px;
      align-self: flex-end;
      margin-top: 23px;
      font-size: @intro-xl;
      line-height: @intro-xl-lh;
    }
  }

  @media @lg {
    width: @bp-container-lg;
    margin: auto;
    margin-bottom: 20px;
  }

  @media @xl {
    width: @bp-container-xl;
    // min-height: 300px;
  }
}

// styles to apply once we've scrolled
body.scrolled {
  .masthead {
    width: 100%;
    height: 68px;
    background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 61%
    );
    border-top: solid @tl-scroll-height @color-light-grey;

    &__inner-wrapper {
      @media @md {
        width: @bp-container-md;
        margin: auto;
      }

      @media @lg {
        width: @bp-container-lg;
        justify-content: space-between;
      }

      @media @xl {
        width: @bp-container-xl;
        flex-direction: row;
      }
    }

    &__headline {
      border-top-width: @tl-scroll-height;
      font-size: @h1-sm-scrolled;
      margin-top: -@tl-scroll-height;

      @media @md-only {
        &-block {
          text-align: center;
        }
      }

      @media @xl {
        padding-top: 10px;
      }

      &-wrapper {
        width: auto;
      }
    }

    &__links,
    &__aside,
    &__intro {
      display: none;
    }

    @media @md {
      height: auto;
    }
  }
}

// home page specific styles
body#home {
  @media @xl {
    .masthead {
      &__headline {
        // padding-top: 42px;
        flex-grow: unset;

        &-wrapper {
          width: unset;
        }

        &-block {
          width: unset;
          display: inline-block;
          align-items: unset;
        }
      }

      &__aside {
        text-align-last: left;
      }
    }
  }
}
</style>
