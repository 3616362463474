<template>
  <div class="project__carousel--wrapper">
    <carousel-controls
      :total="imgs.length"
      :pos="pos"
      :backBtn="backBtn"
      :nextBtn="nextBtn"
      v-on:back="prevImage"
      v-on:next="nextImage"
    ></carousel-controls>
    <div
      class="project__carousel"
      :class="{ activated }"
      v-touch:swipe.left="nextImage"
      v-touch:swipe.right="prevImage"
    >
      <carousel-images
        :imgs="imgs"
        :pos="pos"
        :basePath="basePath"
        :imageType="imageType"
      ></carousel-images>
    </div>
  </div>
</template>

<script>
import carouselControls from './carouselControls.vue';
import carouselImages from './carouselImages.vue';

export default {
  name: 'carousel-gallery',
  data() {
    return {
      pos: 1,
      tot: this.imgs.length,
      backBtn: true,
      nextBtn: false,
      activated: false,
    };
  },
  components: {
    'carousel-controls': carouselControls,
    'carousel-images': carouselImages,
  },
  props: ['imgs', 'imageSizes', 'basePath', 'imageType'],
  methods: {
    prevImage: function () {
      // update controls and position info
      if (this.pos !== 1) {
        --this.pos;
        console.log('⬅️');
        this.updateControls();
      } else {
        return;
      }
    },
    nextImage: function () {
      if (this.pos < this.tot) {
        ++this.pos;
        console.log('➡️');
        this.updateControls();
      } else {
        return;
      }
    },
    updateControls: function () {
      this.pos == this.tot ? (this.nextBtn = true) : (this.nextBtn = false);
      this.pos == 1 ? (this.backBtn = true) : (this.backBtn = false);
      this.moveTray();
    },
    moveTray: function () {
      var docWidth = document.body.clientWidth;
      var imageTray = document.querySelector('.project__carousel-images');

      // breakpoints
      var bpXSBaseLine = 375;

      var bpSM = 768;
      var bpMD = 1025;
      var bpLG = 1401;

      //image Sizes
      var imageWidthSM = 340;
      var imageOffsetSM = 10;

      var imageWidthMD = 674;
      var imageOffsetMD = 28;

      var imageWidthLG = 850;
      var imageOffsetLG = imageOffsetMD;

      var imageWidthXL = 927;
      var imageOffsetXL = imageOffsetMD;

      var offsetPctSM = ((imageWidthSM + imageOffsetSM) / bpXSBaseLine) * 100;
      var offsetMD = imageWidthMD + imageOffsetMD;
      var offsetLG = imageWidthLG + imageOffsetLG;
      var offsetXL = imageWidthXL + imageOffsetXL;

      if (docWidth < bpSM) {
        imageTray.style.left = (this.pos - 1) * offsetPctSM * -1 + '%';
      } else if (docWidth >= bpSM && docWidth < bpMD) {
        imageTray.style.left = (this.pos - 1) * offsetMD * -1 + 'px';
      } else if (docWidth >= bpMD && docWidth < bpLG) {
        imageTray.style.left = (this.pos - 1) * offsetLG * -1 + 'px';
      } else {
        imageTray.style.left = (this.pos - 1) * offsetXL * -1 + 'px';
      }
    },
    updateCarouselHeight: function (destroyed = false) {
      if (destroyed == true) {
        return;
      }
      var theCarousel = document.querySelector('.project__carousel');
      var theInfo = document.querySelector('.project-info');
      var docWidth = document.body.clientWidth;

      if (docWidth > 1400) {
        if (theCarousel.offsetHeight < theInfo.offsetHeight) {
          theCarousel.style.height = theInfo.offsetHeight + 'px';
        } else {
          theInfo.style.height = theCarousel.offsetHeight - 30 + 'px';
        }
      } else {
        theCarousel.style.height = 'unset';
        theInfo.style.height = 'unset';
      }
    },
  },
  mounted() {
    this.activated = true;
    this.moveTray();
    window.addEventListener('resize', this.updateCarouselHeight());

    this.updateCarouselHeight();

    // listen for emits from keyboard arrow buttons
    this.$root.$on('next', this.nextImage);
    this.$root.$on('back', this.prevImage);
  },
  beforeDestroy: function () {
    //reset the total for the next time this is mounted
    this.tot = 0;

    window.removeEventListener('resize', this.updateCarouselHeight(true));
    window.removeEventListener('next', this.nextImage);
    window.removeEventListener('back', this.prevImage);
  },
};
</script>

<style lang="less">
@import '/src/less/import-all.less';

// carousel.less
.project__carousel {
  min-height: @carousel-image-height-sm;
  width: 100%;
  margin-bottom: 15px;
  background-color: @color-dark-grey;
  position: relative;
  padding-top: @carousel-top-padding-sm-md;
  padding-bottom: @carousel-top-padding-sm-md;

  @media @md {
    margin-bottom: 0;
  }

  @media @lg {
    padding-top: @carousel-top-padding-lg;
    padding-bottom: @carousel-bottom-padding-lg;
  }

  @media @xl {
    padding-top: @carousel-top-padding-xl;
    padding-bottom: @carousel-bottom-padding-xl;
  }

  &--wrapper {
    width: 100%;
    position: relative;
  }

  &.activated {
    // so we don't see the animations initially
    .project__carousel-images {
      transition: left @carousel-transition;
    }
  }

  &-controls {
    display: none;

    &--back,
    &--next,
    &--location {
      display: inline-block;
    }

    &--back,
    &--next {
      cursor: pointer;

      svg {
        fill: @color-magenta;
      }

      &.disabled {
        svg {
          fill: @color-light-grey;
        }

        pointer-events: none;
      }
    }

    @media @md {
      display: block;
      position: relative;
      width: @bp-container-md;
      text-align: right;
      margin: 0 auto -30px;
      top: -60px;
    }

    @media @lg {
      width: @bp-container-lg;
    }

    @media @xl {
      width: @bp-container-xl;
    }

    svg {
      width: 30px;
      height: 30px;

      @{no-touch} &:hover {
        .cls-3 {
          fill: @color-black;
        }
      }
    }

    &--next {
      svg {
        transform: rotate(180deg);
      }
    }

    &--location {
      font-family: @font-mono-light;
      color: @color-light-grey;
      font-size: 16px;
      letter-spacing: -3px;
      line-height: 30px;
      vertical-align: top;
      padding-top: 2px;

      &--next {
        margin-right: 3px;
      }
    }
  }

  &-images {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
    left: 0;
    width: 100%;

    @media @md {
      width: @carousel-image-width-md;
      margin-left: auto;
      margin-right: auto;
    }

    @media @lg {
      width: @carousel-image-width-lg;
    }

    @media @xl {
      width: @carousel-image-width-xl;
    }
  }

  &--image {
    width: 100%;
    max-width: 100%;
    max-height: 100%;

    &-wrapper {
      flex-basis: percentage(
        unit(((@carousel-image-width-sm) / @bp-xs-baseline))
      );
      margin-right: percentage(
        unit((@carousel-active-image-offset-sm / @bp-xs-baseline))
      );
      flex-shrink: 0;
      opacity: 0.2;

      &.active {
        opacity: 1;
      }

      &:first-child {
        margin-left: percentage(
          unit(
            ((@bp-xs-baseline - @carousel-image-width-sm) / 2 / @bp-xs-baseline)
          )
        );
      }

      &:last-child {
        margin-right: percentage(
          unit(
            ((@bp-xs-baseline - @carousel-image-width-sm) / 2 / @bp-xs-baseline)
          )
        );
      }

      @media @md {
        flex-basis: @carousel-image-width-md;
        height: @carousel-image-height-md;
        margin-right: @carousel-active-image-offset-md;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      @media @lg {
        flex-basis: @carousel-image-width-lg;
        height: @carousel-image-height-lg;
        margin-right: @carousel-active-image-offset-lg;
      }

      @media @xl {
        flex-basis: @carousel-image-width-xl;
        height: @carousel-image-height-xl;
        margin-right: @carousel-active-image-offset-xl;

        &.active {
          margin-left: ((@bp-container-xl - @carousel-image-width-xl) / 2);
        }
      }
    }

    &-mask {
      display: none;
    }
  }
}
</style>
