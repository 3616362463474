<template>
<svg id="caret-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20">
  <path id="caret" d="M2.59,10l8.53-8.53A.87.87,0,0,0,11.18.24h0A.88.88,0,0,0,9.93.28L.87,9.34a.87.87,0,0,0-.19.28.85.85,0,0,0,0,.76.87.87,0,0,0,.19.28l9.06,9.06a.88.88,0,0,0,1.24.05.87.87,0,0,0,0-1.23h0Z"/>
</svg>

</template>

<script>
export default {
  name: "caret-icon"
};
</script>

