import { defineStore } from 'pinia';
// set up the "user" option store, which contains a boolean logged-in value to determine whether or not to show certain content
export const useUserStore = defineStore('user', {
  state: () => {
    return {
      loggedIn: false,
    };
  },
  getters: {
    userLoggedIn: (state) => state.loggedIn,
  },
  actions: {
    setLoggedIn(x) {
      // first check to see if the loggedIn prop is already set
      if (x != this.loggedIn) {
        x == true ? (this.loggedIn = true) : (this.loggedIn = false);
      }
    },
  },
});
