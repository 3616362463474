<template>
  <footer class="siteFooter">
    <p>Copyright &copy; {{ year }} David Jewell &#8226; v{{ vers }}</p>
  </footer>
</template>

<script>
export default {
  name: 'site-footer',
  data() {
    return {
      year: new Date().getFullYear(),
      vers: process.env.VUE_APP_VERSION, // get the app version from the package https://stackoverflow.com/a/63283176
    };
  },
};
</script>
