<template>
  <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Burger Icon</title>
      <defs>
          <path d="M5.2928353,6.975 L25.1425296,6.975 C25.9255784,6.975 26.5603649,7.55922286 26.5603649,8.27989829 C26.5603649,9.00057372 25.9255784,9.58479658 25.1425296,9.58479658 L5.2928353,9.58479658 C4.50978649,9.58479658 3.875,9.00057372 3.875,8.27989829 C3.875,7.55922286 4.50978649,6.975 5.2928353,6.975 Z M5.2928353,13.95 L25.1425296,13.95 C25.9255784,13.95 26.5603649,14.5342229 26.5603649,15.2548983 C26.5603649,15.9755737 25.9255784,16.5597966 25.1425296,16.5597966 L5.2928353,16.5597966 C4.50978649,16.5597966 3.875,15.9755737 3.875,15.2548983 C3.875,14.5342229 4.50978649,13.95 5.2928353,13.95 Z M5.2928353,20.925 L25.1425296,20.925 C25.9255784,20.925 26.5603649,21.5092229 26.5603649,22.2298983 C26.5603649,22.9505737 25.9255784,23.5347966 25.1425296,23.5347966 L5.2928353,23.5347966 C4.50978649,23.5347966 3.875,22.9505737 3.875,22.2298983 C3.875,21.5092229 4.50978649,20.925 5.2928353,20.925 Z"
              id="path-1"></path>
      </defs>
      <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Buttons-/-Burger" transform="translate(-14.000000, -17.000000)">
              <g id="Icons-/-Burger" transform="translate(10.156250, 10.937500)">
                  <mask id="mask-2" fill="white">
                      <use xlink:href="#path-1"></use>
                  </mask>
                  <use id="Combined-Shape" fill="#000000" fill-rule="nonzero" xlink:href="#path-1"></use>
                  <g id="Colors-/-White" mask="url(#mask-2)" fill="#FFFFFF" fill-rule="nonzero">
                      <rect id="Pink" x="0" y="0" width="31" height="31"></rect>
                  </g>
              </g>
          </g>
      </g>
  </svg>
</template>

<script>
export default {
  name: "burger-icon"
};
</script>
