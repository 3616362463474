<template>
  <div id="app-wrapper" class="content" name="top">
    <site-masthead :siteData="this.siteData"></site-masthead>
    <router-view
      :projectList="this.filteredProjectList"
      :imageSizes="this.siteData.imageSizes"
      :longBio="this.siteData.longBio"
      :bioPhoto="this.siteData.bioPhoto"
    ></router-view>
    <site-footer></site-footer>
  </div>
</template>

<script>
import siteMasthead from './components/masthead/masthead.vue';
import siteFooter from './components/footer.vue';
import siteData from './json/siteData.json';
import projectData from './json/projects.json';
import firebase from 'firebase/compat/app';
import { useUserStore } from './stores/userStore';

export default {
  name: 'app-wrapper',
  components: {
    'site-masthead': siteMasthead,
    siteFooter,
  },
  setup() {
    // use the user store set up in ./stores/userStore.js
    const store = useUserStore();

    return {
      store,
    };
  },
  data() {
    return {
      siteData: siteData,
      rawProjectList: projectData, // the unprocessed project list
      pubProjectList: Array, // the projects that are available to everyone
      authProjectList: Array, // the projects available to authed users (e.g. all)
      filteredProjectList: Array, // the projects list that is being used
    };
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          alert('Success!');
          this.$router.push('/');
        })
        .catch((error) => {
          alert(error.message);
          this.$router.push('/');
        });
    },
    selectList() {
      if (this.store.loggedIn == true) {
        this.filteredProjectList = this.authProjectList;
      } else {
        this.filteredProjectList = this.pubProjectList;
      }
      console.log('filtering list...');
    },
  },
  beforeMount() {
    (this.authProjectList = this.rawProjectList),
      (this.pubProjectList = this.rawProjectList.filter(filterAuthed));

    function filterAuthed(p) {
      return p.authRequired == false;
    }

    this.selectList();
  },
  beforeUpdate() {
    this.selectList();
  },
};
</script>

<style lang="less">
@import './less/import-all.less';

// typography.less
body {
  font-family: @font-normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-family: @font-normal;
  font-weight: @font-normal-light;
  font-size: @h1-sm;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: @h1-sm-ls;

  @media @md {
    font-size: @h1-lg;
    letter-spacing: @h1-lg-ls;
  }
}

h2 {
  font-family: @font-normal;
  font-weight: @font-normal-med;
  font-size: @h2-sm;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: @h2-sm-ls;

  @media @md {
    font-size: @h2-lg;
    letter-spacing: @h2-lg-ls;
  }
}

.h2-wrapper {
  border-top: solid @h2-border-height @color-black;
  width: 100%;
  justify-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  h2 {
    margin: -@h2-border-height 0 0;
    padding-top: 10 - @h2-border-height;
  }

  @media @md {
    flex-basis: 100%;
    justify-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media @lg {
    justify-content: flex-start;
  }
}

h3 {
  font-weight: @font-normal-med;
  text-transform: uppercase;
  font-size: @h3;
  line-height: @h3-lh;
  letter-spacing: @h3-ls;
}

.h4-wrapper {
  border-top: solid @h4-border-height @color-black;
  width: 100%;

  h4 {
    display: inline-block;
    font-weight: @font-normal-med;
    text-transform: uppercase;
    line-height: @h4-sm-lh;
    font-size: @h4-sm;
    margin: -@h4-border-height 0 0;
    padding-top: 5 - @h4-border-height;

    @media @lg {
      font-size: @h4-lg;
    }
  }

  @media @md {
    justify-content: flex-start;

    color: @color-white;
    border-top-color: @color-light-grey;
  }
}

// core.less
body {
  margin: 0;
  padding: 0;
  background-color: @color-bg;
  color: @color-black;
  max-width: 100%;
  overflow-x: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 40px;
}

.main {
  .xs-container;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media @md {
    width: @bp-container-md;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: unset;
  }

  @media @lg {
    width: @bp-container-lg;
  }

  @media @xl {
    width: @bp-container-xl;
  }
}

footer.siteFooter {
  position: absolute;
  bottom: 0;
  font-family: @font-mono;
  font-size: @type-small;
  color: @color-light-grey;
}

body.scrolled {
  .main {
    margin-top: 68px;
    overflow: hidden;
  }
}

a,
a:visited {
  color: @color-magenta;
  text-decoration: none;

  @{no-touch} &:hover {
    color: lighten(@color-magenta, 10%);
    text-decoration: underline;
  }
}

// from topline.less
#topline {
  position: fixed;
  z-index: 100;
  background-color: fade(@color-black, 10%);
  background-blend-mode: multiply;
  height: @tl-height;
  width: 100%;
  top: 0;
}

body.scrolled {
  #topline {
    height: @tl-scroll-height;
  }
}
</style>
