import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import VueRouter from 'vue-router';
import Vue2TouchEvents from 'vue2-touch-events';
import App from './App.vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { createPinia, PiniaVuePlugin } from 'pinia';
import projectListing from './components/projects-list/projectsList.vue';
import projectDetail from './components/project-detail.vue';
import aboutPage from './components/about/about.vue';
import authForm from './components/auth/auth';

Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(Vue2TouchEvents);
Vue.use(PiniaVuePlugin);

const routes = [
  {
    path: '/',
    component: projectListing,
    name: 'home',
  },
  {
    path: '/projects/:id',
    component: projectDetail,
    name: 'detail',
  },
  {
    path: '/about',
    component: aboutPage,
    name: 'about',
  },
  {
    path: '/auth',
    component: authForm,
    name: 'auth',
  },
];

const router = new VueRouter({
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// Firebase config
const firebaseConfig = {
  apiKey: 'AIzaSyC0SQwzdc-pJcbft3jTl06QLuFIPkx0o7E',
  authDomain: 'jewellnet-a0552.firebaseapp.com',
  projectId: 'jewellnet-a0552',
  storageBucket: 'jewellnet-a0552.appspot.com',
  messagingSenderId: '624233105617',
  appId: '1:624233105617:web:39d97237c649b86e725df0',
};

// create firebase instance
firebase.initializeApp(firebaseConfig);

// create pinia instance 🍍
const pinia = createPinia();

var app = new Vue({
  el: '#app',
  router,
  firebase,
  pinia,
  render: (h) => h(App),
  data: {
    keyCode: '',
  },
  methods: {
    handleGlobalKeyDown(e) {
      if (e.keyCode == 37) this.$emit('back');
      if (e.keyCode == 39) this.$emit('next');
    },
  },
});

window.addEventListener('keydown', function (e) {
  app.handleGlobalKeyDown(e);
});
