<template>
  <div class="project__wrapper">
    <section
      class="project"
      v-for="project in currentProject"
      :key="project.id"
    >
      <div class="project-header">
        <div class="project-header__title-wrapper h2-wrapper">
          <h2 class="project-header__title">{{ project.name }}</h2>
        </div>
        <span class="project-header__year">{{ project.year }}</span>
        <ul class="project-header__scope">
          <li v-for="aScope in project.scope" :key="aScope.scope">
            {{ aScope }}
          </li>
        </ul>
      </div>
      <carousel-gallery
        :imgs="project.imgs"
        :imageSizes="imageSizes"
        :basePath="project.basePath"
        :imageType="project.imageType"
      ></carousel-gallery>

      <div class="project-info">
        <div class="project-info__content">
          <div class="project-info__section" v-if="project.disc">
            <div class="project-info__section-title-wrapper h4-wrapper">
              <h4 class="project-info__section-title">About</h4>
            </div>
            <p class="project-info__section-text" v-html="project.disc"></p>
            <a
              :href="project.visitLink"
              target="_blank"
              v-if="project.visitLink"
              >View Live</a
            >
          </div>
          <div class="project-info__section" v-if="project.role">
            <div class="project-info__section-title-wrapper h4-wrapper">
              <h4 class="project-info__section-title">Role</h4>
            </div>
            <p class="project-info__section-text" v-html="project.role"></p>
          </div>
          <div class="project-info__section" v-if="project.notes">
            <div class="project-info__section-title-wrapper h4-wrapper">
              <h4 class="project-info__section-title">Technical Notes</h4>
            </div>
            <ul class="project-info__section-list">
              <li v-for="item in project.notes" :key="item" v-html="item"></li>
            </ul>
          </div>
          <div class="project-info__section">
            <div class="project-info__section-title-wrapper h4-wrapper">
              <h4 class="project-info__section-title">Up Next</h4>
            </div>
            <router-link
              class="project-info__next-project"
              :to="{ name: 'detail', params: { id: nextProject.id } }"
              @click.native="updateView"
            >
              <div class="project-info__next-project--img">
                <img
                  :src="nextProject.thumb"
                  :alt="nextProject.name + ' thumbnail'"
                />
              </div>
              <span class="project-info__next-project--name">{{
                nextProject.name
              }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import carousel from './carousel/carousel.vue';
export default {
  name: 'project-item',
  data() {
    return {
      scope: '',
      pjIndex: Number,
      currentProject: Array,
      nextProject: Array,
    };
  },
  props: ['projectList', 'imageSizes'],
  components: {
    'carousel-gallery': carousel,
  },
  computed: {
    filterProject: function () {
      // this computed property filters the project list into a single object matching the route ID
      var pList = this.projectList;
      var filtered = new Array();
      var pID = this.$route.params.id;

      for (var i = 0; i < pList.length; i++) {
        var obj = pList[i];

        if (obj.id == pID) {
          obj.n = i; // add a location index to the object for "up next" functionality
          filtered.push(obj);
        }
      }
      if (filtered.length == 0) {
        this.nope();
        return false;
      }

      return filtered;
    },
  },
  methods: {
    getNextProject: function (p) {
      // take the index of the current project and select the next one (or first if on the last item)
      if (this.currentProject[0].n < this.projectList.length - 1) {
        this.nextProject = this.projectList[p + 1];
      } else {
        // if on the last in the list, the "next" is the first one
        this.nextProject = this.projectList[0];
      }
    },
    updateView: function () {
      // update the project, which should trigger the page to refresh
      this.currentProject = this.filterProject;
      this.getNextProject(this.currentProject[0].n);
    },
    nope: function () {
      console.log(
        'You gotta be logged in to view that. And you should probably just ignore the error that throws here ;)'
      );
      this.$router.push('/auth');
    },
  },
  mounted() {
    document.body.id = 'detail';
    document.body.className = 'scrolled';
    this.updateView();
  },
};
</script>

<style lang="less">
@import '/src/less/import-all.less';

// detail.less
body#detail {
  @media @xl {
    .masthead {
      height: auto;
      margin-bottom: 30px;
      min-height: 0;
    }
  }
}

.project {
  &__wrapper {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;

  @media @md {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: unset;
    overflow-x: unset;
  }

  @media @xl {
    position: relative;
  }

  &-header {
    .xs-container;
    margin-bottom: 20px;

    @media @md {
      flex-basis: @bp-container-md;
    }

    @media @lg {
      flex-basis: @bp-container-lg;
    }

    @media @xl {
      flex-basis: @bp-container-xl;
    }

    &__title {
      &-wrapper {
        @media @md {
          justify-content: flex-start;
        }
      }
    }

    &__year,
    &__scope {
      font-family: @font-mono;
      font-style: italic;
      color: @color-magenta;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      display: block;
      margin-top: 5px;

      @media @md {
        text-align: left;
        display: inline-block;
      }
    }

    &__scope {
      color: @color-light-grey;
      list-style: none;
      padding-left: 10px;
      min-width: @bp-container-sm;

      li {
        display: inline-block;
        margin-right: 10px;

        &:after {
          content: ',';
        }

        &:last-child {
          &:after {
            content: '';
          }
        }
      }

      @media @md {
        width: auto;
      }
    }
  }

  &-info {
    .xs-container;

    a {
      color: lighten(@color-magenta, 10%);
      text-decoration: none;
      font-weight: @font-normal-med;

      @{no-touch} &:hover {
        color: @color-white;
        text-decoration: underline;
      }
    }

    &__content {
      @media @md {
        flex-basis: @bp-container-md;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 10px;
      }

      @media @lg {
        flex-basis: @bp-container-lg;
      }

      @media @xl {
        flex-basis: 319px;
        background-color: @color-dark-grey;
        padding-left: 40px;
        padding-right: 10px;
        padding-top: 0;
      }
    }

    &__section {
      margin-bottom: 40px;

      &-title {
        margin-bottom: 15px;
      }

      &-text,
      &-list {
        font-family: @font-normal;
        color: @color-dark-grey;
        font-size: @project-info-text-sm;
        line-height: @project-info-text-sm-lh;

        @media @md {
          color: @color-white;
        }

        @media @lg {
          font-size: @project-info-text-lg;
          line-height: @project-info-text-lg-lh;
        }
      }

      &-list {
        padding-left: 30px;
      }

      @media @md {
        flex-basis: 323px;
      }

      @media @lg {
        flex-basis: 237px;

        &:first-child {
          flex-basis: 326px;
        }
      }
    }

    &__next-project {
      position: relative;
      transition: @default-transition;
      top: 15px;

      &--name {
        width: 100%;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(0, -50%);

        @media @md {
          color: @color-white;
        }
      }
      &--img {
        // background-color: @color-magenta;
        img {
          width: 100%;
          filter: grayscale(100%) !important;
          opacity: 0.1;
        }

        @media @md {
          backgorund-color: @color-magenta;

          img {
            opacity: 0.4;
          }
        }
      }

      @{no-touch} &:hover {
        div {
          background-color: @color-magenta;
        }

        img {
          opacity: 0.2;
        }
      }
    }
    @media @md {
      flex-basis: 100%;
      background-color: @color-dark-grey;
      display: flex;
      justify-content: center;
    }

    @media @xl {
      position: absolute;
      top: 85px;
      background-color: transparent;
      width: @bp-container-xl + 80px;
      justify-content: flex-start;
      padding-top: @carousel-top-padding-xl;
    }
  }
}
</style>
