<template>
  <div class="project-listing">
    <router-link :to="{ name: 'detail', params: { id: project.id } }">
      <div class="project-listing__img">
        <img :src="project.thumb" />
      </div>
      <h3 class="project-listing__title">
        <lock-svg v-if="project.authRequired == true" unlocked />
        {{ project.name }}
      </h3>
    </router-link>
  </div>
</template>

<script>
import lockSvg from '@/components/svg/lock.svg.vue';

export default {
  name: 'project-list-item',
  props: ['project'],
  components: {
    'lock-svg': lockSvg,
  },
};
</script>
