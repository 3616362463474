<template>
  <span>
    <svg
      v-if="this.unlocked != true"
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      width="20"
      class="locked"
    >
      <path
        d="M5.5 18q-.625 0-1.062-.438Q4 17.125 4 16.5v-8q0-.625.438-1.062Q4.875 7 5.5 7H6V5q0-1.667 1.167-2.833Q8.333 1 10 1q1.667 0 2.833 1.167Q14 3.333 14 5v2h.5q.625 0 1.062.438Q16 7.875 16 8.5v8q0 .625-.438 1.062Q15.125 18 14.5 18Zm4.5-4q.625 0 1.062-.438.438-.437.438-1.062t-.438-1.062Q10.625 11 10 11t-1.062.438Q8.5 11.875 8.5 12.5t.438 1.062Q9.375 14 10 14ZM7.5 7h5V5q0-1.042-.729-1.771Q11.042 2.5 10 2.5q-1.042 0-1.771.729Q7.5 3.958 7.5 5Z"
      />
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      width="20"
      class="unlocked"
    >
      <path
        d="M5.083 18.333q-.729 0-1.239-.521-.511-.52-.511-1.25V8.417q0-.729.511-1.24.51-.51 1.239-.51h7.375V5.042q0-1.021-.718-1.74-.719-.719-1.74-.719-.812 0-1.448.48-.635.479-.885 1.208-.105.354-.365.562-.26.209-.552.209-.417 0-.677-.292-.261-.292-.177-.667.333-1.416 1.469-2.333Q8.5.833 10 .833q1.771 0 2.99 1.219 1.218 1.219 1.218 2.99v1.625h.709q.729 0 1.239.51.511.511.511 1.24v8.145q0 .73-.511 1.25-.51.521-1.239.521ZM10 14.188q.708 0 1.198-.49t.49-1.198q0-.708-.49-1.198T10 10.812q-.708 0-1.198.49t-.49 1.198q0 .708.49 1.198t1.198.49Z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'lock-unlock-svg',
  props: { unlocked: Boolean },
};
</script>

<style scoped>
svg {
  vertical-align: bottom; /* align to bottom to better sit next to text */
}
</style>
