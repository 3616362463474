<template>
  <section class="about">
    <div class="about__header">
      <div class="h2-wrapper">
        <h2>About Me</h2>
      </div>
    </div>
    <div class="about__content">
      <div class="about__content-photo">
        <img :src="this.bioPhoto" alt="David Jewell" />
      </div>
      <div class="about__content-long-bio" v-html="this.longBio"></div>
      <section class="about__contact">
        <div class="about__content-long-bio" v-html="this.longBio"></div>
        <div class="h2-wrapper">
          <h2>Say Hi</h2>
        </div>
        <contact-form></contact-form>
      </section>
    </div>
  </section>
</template>

<script>
import contactForm from './contactForm.vue';

// utilizes elements from https://github.com/jcottrell/vue-example-contact-form
export default {
  name: 'about-page',
  components: {
    'contact-form': contactForm,
  },
  props: ['longBio', 'bioPhoto'],
  mounted() {
    document.body.id = 'about';
    document.body.className = 'scrolled';

    document.querySelector('.masthead__menu').classList.remove('open'); // hide the burger menu when clicking through from burger nav
  },
};
</script>

<style lang="less">
@import '/src/less/import-all.less';

// about.less
body#about {
  @media @xl {
    .masthead {
      height: auto;
      min-height: 0;
    }
  }
}

.about {
  width: 100%;
  overflow-x: hidden;

  @media @md {
    width: @bp-container-md;
  }

  @media @lg {
    width: @bp-container-lg;
  }

  @media @xl {
    width: @bp-container-xl;
  }

  &__header {
    .xs-container;
    margin-left: auto;
    margin-right: auto;

    @media @md {
      width: auto;
    }
  }

  &__content {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media @md {
      width: @bp-container-md;
      margin-left: auto;
      margin-right: auto;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media @lg {
      width: @bp-container-lg;
    }

    @media @xl {
      width: @bp-container-xl;
      justify-content: flex-start;
    }

    &-photo {
      width: 100vw;
      min-height: 100%;
      overflow: hidden;
      margin-bottom: 20px;

      img {
        max-width: 100%;
        margin-top: -12%;
      }

      @media @md {
        width: 321px;

        img {
          margin-top: 0;
        }
      }

      @media @lg {
        flex-shrink: 0;
      }

      @media @xl {
        width: 409px;
      }
    }

    &-long-bio {
      .xs-container;

      font-size: 14px;
      line-height: 21px;
      margin-bottom: 30px;

      @media @md {
        width: 320px;
      }

      @media @lg {
        width: 497px;
        margin-left: 30px;
      }

      @media @xl {
        display: none;
      }
    }
  }

  &__contact {
    .xs-container;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: @burger-width-sm;

    .about__content-long-bio {
      display: none;
    }

    @media @md {
      width: @bp-container-md;
    }

    @media @lg {
      width: @bp-container-lg;
    }

    @media @xl {
      width: 783px;

      .about__content-long-bio {
        display: block;
        width: 606px;
        margin-left: 60px;
        margin-bottom: 40px;
      }
    }
  }
}

#contact-form {
  margin-top: 40px;
  width: 100%;

  @media @md {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .contact-form {
    &__submitting-message {
      text-align: center;
      min-height: 100px;
      line-height: 100px;
      width: 100%;
    }
    &__group {
      margin-bottom: 20px;
      position: relative;

      @media @md {
        &.name-group,
        &.email-group {
          width: 319px;
          flex-grow: 0;
        }

        &.message-group {
          flex-grow: 1;
          flex-basis: 100%;
        }
      }

      @media @lg {
        &.name-group,
        &.email-group {
          width: 407px;
        }
      }

      @media @xl {
        &.name-group {
          width: 349px;
        }
      }
    }

    &__error-text,
    &__requirments {
      font-size: @type-small;
      line-height: @type-small + 2px;
      font-weight: @font-normal-book;
      display: block;
      margin: 5px 0 0;
    }

    &__error-text {
      color: @color-warning;
      font-weight: @font-normal-med;
    }

    &__requirments {
      color: @color-light-grey;
    }

    &__submit-button {
      appearance: none;
      font-weight: @font-normal-med;
      text-transform: uppercase;
      border-radius: 3px;
      overflow: hidden;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.71px;
      padding: 5px 10px;
      color: @color-white;
      background-color: @color-magenta;

      &:disabled {
        background-color: @color-light-grey;
        cursor: not-allowed;
      }
    }
  }
  label {
    font-size: @type-xs;
    letter-spacing: @h2-sm-ls;
    font-weight: @font-normal-med;
    text-transform: uppercase;
    position: absolute;
    top: 5px;
    left: 5px;
    color: @color-dark-grey;

    &.invalid {
      color: @color-warning;
    }
  }

  input,
  textarea {
    width: 100%;
    box-sizing: border-box;
    border: none;
    padding: 15px 5px 2px;
    font-family: @font-mono;
    font-size: 14px;
    line-height: 21px;
    color: @color-dark-grey;
    background-color: @color-lightest-grey;
    appearance: none;
    border-radius: 3px;
    overflow: hidden;

    &.valid {
      background-color: @color-success-bg;
    }

    &.invalid {
      background-color: @color-warning-bg;
      color: @color-warning;
    }
  }
}
</style>
