import { render, staticRenderFns } from "./siteMenu.vue?vue&type=template&id=5ba36e94"
import script from "./siteMenu.vue?vue&type=script&lang=js"
export * from "./siteMenu.vue?vue&type=script&lang=js"
import style0 from "./siteMenu.vue?vue&type=style&index=0&id=5ba36e94&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports