<template>
  <div class="auth__wrapper">
    <h2 class="login-headline">{{ headlineMessage }}</h2>
    <div v-if="this.userStore.userLoggedIn == false">
      <form @submit.prevent="login" class="login-form">
        <input
          type="email"
          name="email"
          placeholder="Email"
          v-model="email"
          hidden
        />
        <div class="helper-wrapper">
          <div class="input-wrapper">
            <input
              type="password"
              name="password"
              class="input-password"
              id="pass"
              placeholder="Password"
              v-model="password"
            />
            <span class="password-toggle" @click="togglePasswordVis"
              ><show-icon id="passToggle" :vis="this.passViz"
            /></span>
          </div>
          <small id="helper-text">{{ helperText }}</small>
        </div>
        <button type="submit">Log In</button>
      </form>
    </div>
    <div class="logout-form" v-if="this.userStore.userLoggedIn == true">
      <button @click="logout">Log Out</button>
    </div>
  </div>
</template>

<script>
import firebase from '@firebase/app-compat';
import { useUserStore } from '@/stores/userStore';
import showSVG from '../svg/icon-show.svg.vue';

export default {
  name: 'auth-form',
  components: {
    'show-icon': showSVG,
  },
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
    };
  },
  data() {
    return {
      email: 'portfolio@davidjewell.nyc',
      password: '',
      helperText: '',
      passViz: false,
      loginToken: false,
    };
  },
  computed: {
    headlineMessage() {
      // set the auth form headline dynamically based on the logged in state
      if (this.userStore.userLoggedIn == false) {
        return 'Please log in to view protected projects';
      } else {
        return 'Log out';
      }
    },
  },
  methods: {
    login() {
      if (this.password.length < 1) {
        this.showHelperText(true);
        this.helperText = 'Please enter a password.';
        return;
      } else {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            console.log('Login success! 🎉');
            this.userStore.setLoggedIn(true);
            this.$router.push('/');
            this.showHelperText(false);
          })
          .catch((error) => {
            // from https://github.com/firebase/quickstart-js/blob/master/auth/email-password.html
            // Handle Errors here.
            this.showHelperText(true);
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
              this.helperText = 'Incorrect Password.';
            } else {
              this.helperText = errorMessage;
            }
            console.log('error:', errorCode, errorMessage);
          });
      }
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          console.log('Logged Out');
          this.userStore.setLoggedIn(false);
          this.$router.push('/');
        })
        .catch((error) => {
          console.log('oops!', error.message);
        });
    },
    showHelperText(x) {
      const ht = document.getElementById('helper-text');
      // call it to show/hide helper text
      x == true ? (ht.className = 'visible') : (ht.className = null);
    },
    togglePasswordVis() {
      const pw = document.getElementById('pass');
      // const tog = document.getElementById('passToggle');
      if (pw.type == 'password') {
        pw.type = 'text';
        this.passViz = true;
      } else {
        pw.type = 'password';
        this.passViz = false;
      }
    },
  },
  beforeMount() {
    if (this.$route.query.pw != null) {
      this.password = this.$route.query.pw;
      this.login();
    }
  },
};
</script>

<style lang="less">
@import '/src/less/import-all.less';

h2,
h3 {
  margin-bottom: 20px;
  text-align: center;
}
form {
  margin-bottom: 40px;
}

.auth {
  &__wrapper {
    width: 100%;
    overflow-x: hidden;
    .xs-container;
    padding: 1rem;

    @media @md {
      width: @bp-container-md;
    }
  }
}

input,
button {
  height: @input-height;
  box-sizing: content-box;
  padding: @input-padding-top (@input-padding-left);
}

button {
  margin-top: 0.5rem;
  background-color: @color-magenta;
  color: @color-white;
  cursor: pointer;
  transition: background-color @timing-default;
  border: solid 2px @color-magenta;

  @{no-touch} &:hover {
    background-color: lighten(@color-magenta, 25%);
  }

  @media @md {
    width: auto;
    margin-top: 0;
    margin-left: 1rem;
  }
}

.login-form {
  display: flex;
  flex-direction: column;

  @media @md {
    flex-direction: row;
    justify-content: center;
    align-items: top;
  }
}

.helper-wrapper {
  display: flex;
  flex-direction: column;
}

.input-wrapper {
  display: flex;
  position: relative;

  .input-password {
    font-family: @font-mono;
    font-size: @input-font-size;
    height: @input-height;
    padding: @input-padding-top @input-padding-left;
    padding-right: @input-padding-right;
    flex-basis: 100%;
  }

  .password-toggle {
    position: absolute;
    top: @input-toggle-offset;
    right: @input-toggle-offset;
    height: @input-height - (@input-toggle-offset * 2);
    width: @input-padding-right - (@input-toggle-offset * 2);
    padding: (@input-padding-top + 2) 0;
    text-align: center;
    cursor: pointer;
    border-radius: 2px;
    svg {
      width: @input-show-toggle-size;
      height: @input-show-toggle-size;
      margin-top: 2px;
      fill: @color-dark-grey;
    }

    @{no-touch} &:hover {
      background-color: @color-magenta;
      svg {
        fill: @color-white;
      }
    }
  }
}

.logout-form {
  text-align: center;
}

#helper-text {
  font-size: @type-small;
  font-weight: @font-normal-med;
  line-height: @type-small-lh;
  padding: 1rem;
  margin-top: 0.25rem;
  color: @color-warning;
  background-color: @color-warning-bg;
  border-radius: 2px;
  max-width: @bp-container-sm;
  display: none;

  &.visible {
    display: block;
  }
}
</style>
