<template>
  <section class="main">
    <div class="main__title-wrapper h2-wrapper">
      <a name="work"></a>
      <h2 class="main__title">Selected Work</h2>
    </div>
    <project-list-item
      v-for="project in projectList"
      v-bind:key="project.id"
      v-bind:project="project"
    ></project-list-item>
  </section>
</template>

<script>
import projectListItem from './projectListItem.vue';

export default {
  name: 'projects-list',
  components: {
    'project-list-item': projectListItem,
  },
  mounted() {
    document.body.id = 'home';
    document.body.className = '';
  },
  props: ['projectList'],
};
</script>

<style lang="less">
@import '/src/less/import-all.less';

// project-listing.less
.project-listing {
  max-width: 100%;
  margin: (@listing-spacing-sm / 2) 0 (@listing-spacing-sm / 2);

  a {
    text-decoration: none;
    color: @color-black;

    @{no-touch} &:hover {
      color: @color-magenta;
      text-decoration: none;

      .project-listing__img {
        background-color: @color-magenta;

        img {
          filter: grayscale(100%);
          opacity: 0.4;
        }
      }
    }
  }

  &__img {
    min-width: 100%;
    height: @listing-size-sm;
    overflow: hidden;
    display: flex;
    justify-content: center;

    img {
      max-height: 100%;
      transition: all @default-transition;
    }
  }

  &__title {
    margin-top: 7px;
  }

  @media @md {
    width: 320px;
  }

  @media @lg {
    width: 409px;
  }
}
</style>
