<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <title>strava</title>
      <path d="M20.516 23.925l-2.785-5.488h-4.087l6.872 13.563 6.867-13.563h-4.088zM13.951 10.972l3.781 7.464h5.563l-9.344-18.436-9.333 18.437h5.559z"></path>
  </svg>
</template>

<script>
export default {
  name: "strava-svg"
};
</script>
